import {JesterSvg} from '@fool/jester-ui/JesterHat/JesterSvg'

type AvatarProps = {
  altText: string
  tier: number
  userImage?: string
}

function AvatarTiers({altText, tier, userImage}: AvatarProps) {
  const defaultFill = '#C3CAEE'
  const tierTwoFill = tier >= 2 ? '#E81D75' : defaultFill
  const tierThreeFill = tier >= 3 ? '#FE6B00' : defaultFill
  const tierFourFill = tier >= 4 ? '#3AAE2A' : defaultFill
  const tierFiveFill = tier >= 5 ? '#385CC7' : defaultFill

  return (
    <>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.503 4.06911L18.5006 0C10.7885 0.572377 4.2935 5.5242 1.48673 12.3809L5.32125 13.6231C7.59564 8.39502 12.5813 4.61856 18.503 4.06911ZM34.6795 13.6248C32.4062 8.39761 27.4227 4.62119 21.503 4.06967L21.5006 9.23095e-05C29.2123 0.57292 35.7069 5.52474 38.5134 12.3813L34.6795 13.6248ZM30.5891 31.9949C33.9074 29.0633 36 24.776 36 20C36 18.7908 35.8658 17.6128 35.6116 16.4803L39.4382 15.2391C39.8054 16.7567 40 18.3417 40 19.9723C40 26.0905 37.2604 31.5679 32.9426 35.2416L30.5891 31.9949ZM11.8402 33.7658C14.2294 35.1851 17.0195 36 20 36C22.9822 36 25.7739 35.1841 28.1639 33.7633L30.5176 37.0103C27.462 38.9058 23.8586 40 20 40C16.1418 40 12.5387 38.906 9.48326 37.0108L11.8402 33.7658ZM4.38878 16.4785C4.13429 17.6116 4 18.7901 4 20C4 24.7777 6.09412 29.0664 9.41457 31.9981L7.05822 35.2422C2.73996 31.5686 0 26.0909 0 19.9723C0 18.3416 0.194622 16.7564 0.561876 15.2388L4.38878 16.4785Z"
          fill="#C3CAEE"
        />
        <path
          id="tier-1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5024 4.0697L21.5 0C29.2117 0.572827 35.7063 5.52465 38.5128 12.3812L34.6785 13.6249C32.4053 8.39773 27.4219 4.62134 21.5024 4.0697Z"
          fill="#A941AA"
        />
        <path
          id="tier-2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5898 31.995C33.9082 29.0633 36.0009 24.776 36.0009 20C36.0009 18.7907 35.8667 17.6127 35.6125 16.4801L39.4388 15.239C39.806 16.7565 40.0006 18.3416 40.0006 19.9722C40.0006 26.0904 37.261 31.5678 32.9432 35.2415L30.5898 31.995Z"
          fill={tierTwoFill}
        />
        <path
          id="tier-3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1651 33.7632C25.775 35.1841 22.9833 36 20.001 36C17.0205 36 14.2304 35.185 11.8413 33.7658L9.48438 37.0107C12.5398 38.9059 16.1429 39.9999 20.0011 39.9999C23.8597 39.9999 27.4631 38.9057 30.5187 37.0102L28.1651 33.7632Z"
          fill={tierThreeFill}
        />
        <path
          id="tier-4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.38974 16.4786L0.56265 15.2388C-1.19524 22.5031 1.34754 30.3834 7.059 35.2423L9.41543 31.998C6.09505 29.0663 4.00098 24.7777 4.00098 20C4.00098 18.7901 4.13526 17.6116 4.38974 16.4786Z"
          fill={tierFourFill}
        />
        <path
          id="tier-5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.3212 13.6233C7.59552 8.39519 12.5811 4.61874 18.5026 4.06914L18.5002 0C10.7881 0.572377 4.2931 5.5242 1.48633 12.3809L5.3212 13.6233Z"
          fill={tierFiveFill}
        />
        {userImage && (
          <>
            <circle cx="20" cy="20" r="14" fill="#fff" x="13" y="13" />
            <image
              href={userImage}
              width="30"
              height="30"
              x="5"
              y="5"
              clipPath="url(#circle-clip)"
              aria-labelledby={altText}
            />
            <clipPath id="circle-clip">
              <circle cx="20" cy="20" r="13" x="10" y="10" />
            </clipPath>
          </>
        )}
        {!userImage && (
          <svg viewBox="0 0 10 10" x="4" width="32" aria-labelledby={altText}>
            <circle cx="5" cy="5" r="4" fill="#C3CAEE" x="6" y="6" />
            <JesterSvg />
          </svg>
        )}
      </svg>
    </>
  )
}

export default AvatarTiers
