'use client'
import {useEffect} from 'react'
import {loadSpace} from '@usersnap/browser'
import {useSession} from 'next-auth/react'
import {FoolSession} from '~auth/options.ts'
import {
  NEXT_PUBLIC_USERSNAP_SPACE_API_KEY,
  NEXT_PUBLIC_EMPLOYEE_ONLY_USERSNAP_SPACE_API_KEY,
  ENVIRONMENT,
} from '~data/constants.ts'

export default function Usersnap() {
  const shouldLoadUserSnap = ENVIRONMENT === 'production'
  const session = useSession()?.data as FoolSession | null

  useEffect(() => {
    if (shouldLoadUserSnap && NEXT_PUBLIC_USERSNAP_SPACE_API_KEY && session) {
      if (
        (session.isEmployee || session.isContractor) &&
        NEXT_PUBLIC_EMPLOYEE_ONLY_USERSNAP_SPACE_API_KEY
      ) {
        // By setting a "type" with Usersnap's API it allows us to conditionally
        // deliver Usersnap forms both by url and what they call segments
        // In our case we need different Usersnap forms to show
        // for employees and members, since they have different legalease
        // and different Slack integrations
        loadSpace(NEXT_PUBLIC_EMPLOYEE_ONLY_USERSNAP_SPACE_API_KEY).then(
          (api) =>
            api.init({
              user: {
                userId: session.foolUid?.toString() || '',
                type: 'employee',
              },
              custom: {
                // leave this field here unless BI is sure they don't need it
                // can check with Sujin
                employee_or_contractor: true,

                // Include identifying information for employees and contractors
                email: session.email,
                name: session.name,
              },
            }),
        )
      } else
        loadSpace(NEXT_PUBLIC_USERSNAP_SPACE_API_KEY).then((api) =>
          api.init({
            user: {
              userId: session.foolUid?.toString() || '',
              type: 'member',
            },
            custom: {
              member_level: session?.level,
              new_member_level: session?.membershipLevel,
              member: true,
              memberUid: session.foolUid?.toString() || '',
            },
          }),
        )
    }
  }, [])

  return null
}
