'use client'
import {useEffect, useState} from 'react'
import {usePathname} from 'next/navigation'
import getPageLayoutData from '~data/getPageLayoutData'
import {PageLayoutData} from '~data/types/page'
import {useSession} from 'next-auth/react'
import HeaderSearch from '~components/layout/header-search'
import HeaderTitle from './header-title'
import AdPlacement from '@fool/jester-ui/AdPlacement'

function HeaderLeft() {
  const {data: session} = useSession()
  const pathname = usePathname()
  const [pageInfo, setPageInfo] = useState<PageLayoutData>({})

  useEffect(() => {
    setPageInfo(getPageLayoutData(pathname))
  }, [pathname])

  const defaultValues = {
    showTickerTape: false,
    headerTitle: null,
    hasServiceMenu: false,
    hasStrategiesMenu: false,
  }
  const {headerTitle, hasServiceMenu, hasStrategiesMenu, showTickerTape} = {
    ...defaultValues,
    ...pageInfo,
  }

  const hasTitle = hasServiceMenu || hasStrategiesMenu || headerTitle

  const headerSearchWidth = `w-full max-w-none xl:max-w-[500px] ${
    hasTitle && showTickerTape ? '' : 'xxl:max-w-[564px]'
  }`

  return (
    <div id="global-header" className="flex items-center w-full">
      <div className="hidden lg:block lg:w-1/4 shrink-0">
        <HeaderTitle />
      </div>
      <div
        id="global-header--search-wrapper"
        className="flex justify-center w-full lg:w-1/2"
      >
        <HeaderSearch
          customClasses={`md:relative flex-grow-0 ${headerSearchWidth}`}
        />
      </div>
      <div className="lg:w-1/4 shrink-0">
        <AdPlacement
          pitchPlacement={'premium_header_cta_desktop'}
          pitchClassName={'hidden xl:block align-center px-8px max-w-xs'}
          authToken={session?.accessToken}
        >
          {/* <!--
            ***
            premium_header_cta_desktop
            ***
          -->
          <!-- variable href -->
          <a id="premium_header_cta_desktop" href="">
            <button
              type="button"
              class="text-button font-black py-8px flex justify-center w-full gap-8px px-16px rounded-[8px] bg-primary-100 text-primary-0 hover:bg-primary-120 focus:bg-primary-120 active:bg-content-100"
            >
              <!-- variable CTA copy -->
              Upgrade
            </button>
          </a> */}
        </AdPlacement>
      </div>
    </div>
  )
}

export default HeaderLeft
