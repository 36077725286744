export const getPandoRoot = () => {
  return 'https://stockdata.fool.com'
  //const foolEnv = process.env.NEXT_PUBLIC_FOOL_ENV
  // return foolEnv === 'production'
  //   ? 'https://stockdata.fool.com'
  //   : 'https://pando.staging.fool.com'
}

export const getPandoPath = (
  instrument: number | undefined | null,
  exchange: string | undefined | null,
  symbol: string | undefined | null,
) => {
  const pathPostfix = 'financials/summary'
  if (instrument)
    return `${getPandoRoot()}/company/${instrument}/${pathPostfix}`
  if (exchange && symbol)
    return `${getPandoRoot()}/company/${exchange}/${symbol}/${pathPostfix}`
  return `${getPandoRoot()}/company/${symbol}/${pathPostfix}`
}

export const pandoCanHandle = (exchange?: string, instrumentId?: number) => {
  const notYetHandledExchanges = ['']
  const notYetHandledInstrumentIds: number[] = []
  const okExchange =
    !exchange || !notYetHandledExchanges.includes(exchange.toLowerCase())
  const okInstrumentId =
    !instrumentId || !notYetHandledInstrumentIds.includes(instrumentId)
  return okExchange && okInstrumentId
}
