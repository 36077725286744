'use client'

import {usePathname} from 'next/navigation'
import {twMerge} from 'tailwind-merge'
import {Link} from '~components/common/link'
import {useFool24LivestreamSchedule} from '~hooks/use-fool24-livestream-schedule'
import styles from './fool24-button.module.css'

const EXCLUDE_PATHS = ['/news-and-analysis/fool24', '/ai-summit']

export default function Fool24Button() {
  const {isLive} = useFool24LivestreamSchedule()
  const pathname = usePathname()

  if (EXCLUDE_PATHS.some((path) => pathname.startsWith(path))) {
    return null
  }

  return (
    <div
      className={twMerge(
        'svg-container flex items-center w-[72px] h-[72px]',
        styles['svg-container'],
      )}
    >
      <Link href="/news-and-analysis/fool24">
        <button className="bg-transparent w-[72px] h-[72px] p-0 hover:bg-transparent">
          <div className={twMerge('svg-wrapper', styles['svg-wrapper'])}>
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={twMerge('svg-one', styles['svg-one'])}
            >
              <g filter="url(#filter0_d_315_15648)">
                <circle cx="36" cy="34" r="20" fill="#C3CAEE" />
              </g>
              <circle cx="36" cy="34" r="16" fill="#0522BA" />
              <g filter="url(#filter1_d_315_15648)">
                <path
                  d="M27.7003 25.1517V33.1676H26V25.1517H27.7003ZM30.9436 26.7765H26.1574V25.1517H30.9436V26.7765ZM30.4293 30.0371H26.1155V28.4555H30.4293V30.0371Z"
                  fill="white"
                />
                <path
                  d="M31.2264 30.4487C31.2264 29.8782 31.3488 29.3799 31.5937 28.9538C31.8456 28.5205 32.1885 28.1847 32.6223 27.9464C33.0632 27.7081 33.5635 27.5889 34.1232 27.5889C34.683 27.5889 35.1798 27.7081 35.6137 27.9464C36.0475 28.1847 36.3869 28.5205 36.6318 28.9538C36.8837 29.3799 37.0096 29.8782 37.0096 30.4487C37.0096 31.0192 36.8837 31.5175 36.6318 31.9436C36.3869 32.3696 36.0475 32.7018 35.6137 32.9401C35.1798 33.1784 34.683 33.2976 34.1232 33.2976C33.5635 33.2976 33.0632 33.1784 32.6223 32.9401C32.1885 32.7018 31.8456 32.3696 31.5937 31.9436C31.3488 31.5175 31.2264 31.0192 31.2264 30.4487ZM32.8532 30.4379C32.8532 30.7123 32.9057 30.9542 33.0107 31.1636C33.1226 31.3658 33.2731 31.5247 33.462 31.6403C33.6509 31.7486 33.8713 31.8027 34.1232 31.8027C34.3751 31.8027 34.5956 31.7486 34.7845 31.6403C34.9734 31.5247 35.1204 31.3658 35.2253 31.1636C35.3303 30.9542 35.3828 30.7123 35.3828 30.4379C35.3828 30.1634 35.3303 29.9251 35.2253 29.7229C35.1204 29.5207 34.9734 29.3654 34.7845 29.2571C34.5956 29.1416 34.3751 29.0838 34.1232 29.0838C33.8713 29.0838 33.6509 29.1416 33.462 29.2571C33.2731 29.3654 33.1226 29.5207 33.0107 29.7229C32.9057 29.9251 32.8532 30.1634 32.8532 30.4379Z"
                  fill="white"
                />
                <path
                  d="M37.6633 30.4487C37.6633 29.8782 37.7858 29.3799 38.0307 28.9538C38.2826 28.5205 38.6254 28.1847 39.0593 27.9464C39.5001 27.7081 40.0004 27.5889 40.5602 27.5889C41.12 27.5889 41.6168 27.7081 42.0506 27.9464C42.4844 28.1847 42.8238 28.5205 43.0687 28.9538C43.3206 29.3799 43.4465 29.8782 43.4465 30.4487C43.4465 31.0192 43.3206 31.5175 43.0687 31.9436C42.8238 32.3696 42.4844 32.7018 42.0506 32.9401C41.6168 33.1784 41.12 33.2976 40.5602 33.2976C40.0004 33.2976 39.5001 33.1784 39.0593 32.9401C38.6254 32.7018 38.2826 32.3696 38.0307 31.9436C37.7858 31.5175 37.6633 31.0192 37.6633 30.4487ZM39.2902 30.4379C39.2902 30.7123 39.3427 30.9542 39.4476 31.1636C39.5596 31.3658 39.71 31.5247 39.8989 31.6403C40.0879 31.7486 40.3083 31.8027 40.5602 31.8027C40.8121 31.8027 41.0325 31.7486 41.2214 31.6403C41.4103 31.5247 41.5573 31.3658 41.6622 31.1636C41.7672 30.9542 41.8197 30.7123 41.8197 30.4379C41.8197 30.1634 41.7672 29.9251 41.6622 29.7229C41.5573 29.5207 41.4103 29.3654 41.2214 29.2571C41.0325 29.1416 40.8121 29.0838 40.5602 29.0838C40.3083 29.0838 40.0879 29.1416 39.8989 29.2571C39.71 29.3654 39.5596 29.5207 39.4476 29.7229C39.3427 29.9251 39.2902 30.1634 39.2902 30.4379Z"
                  fill="white"
                />
                <path d="M46 33.1676H44.3836V25H46V33.1676Z" fill="white" />
                <path
                  d="M35.3064 43.9783L29.7646 44V42.7001L32.3256 40.5011C32.5355 40.3278 32.7139 40.1653 32.8609 40.0137C33.0078 39.8548 33.1268 39.7032 33.2177 39.5587C33.3157 39.4071 33.3857 39.259 33.4276 39.1146C33.4766 38.9629 33.5011 38.8041 33.5011 38.638C33.5011 38.2336 33.4067 37.9266 33.2177 37.7172C33.0358 37.5006 32.7699 37.3922 32.42 37.3922C32.0562 37.3922 31.7728 37.515 31.5699 37.7605C31.374 38.0061 31.276 38.3527 31.276 38.8005H29.5862C29.5862 38.2011 29.7016 37.6811 29.9325 37.2406C30.1704 36.7929 30.4993 36.4462 30.9191 36.2007C31.346 35.9551 31.8428 35.8324 32.4095 35.8324C33.0043 35.8324 33.5116 35.9407 33.9314 36.1574C34.3513 36.3668 34.6732 36.6737 34.8971 37.0781C35.128 37.4753 35.2434 37.9591 35.2434 38.5296C35.2434 38.8041 35.2049 39.0677 35.128 39.3204C35.051 39.5659 34.9426 39.8043 34.8026 40.0353C34.6627 40.2664 34.4947 40.4903 34.2988 40.707C34.1029 40.9236 33.8825 41.1366 33.6376 41.3461L31.9162 42.8301L31.2865 42.3535H35.3064V43.9783Z"
                  fill="#FFCC32"
                />
                <path
                  d="M41.3045 35.984V44H39.6986V35.984H41.3045ZM35.8361 41.0428L39.1843 35.984H40.9791L37.5575 41.1727L35.8361 41.0428ZM42.2596 42.581H35.8361V41.0428H42.2596V42.581Z"
                  fill="#FFCC32"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_315_15648"
                  x="0"
                  y="0"
                  width="72"
                  height="72"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="8" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.0392157 0 0 0 0 0.219608 0 0 0 0.16 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_315_15648"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_315_15648"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_315_15648"
                  x="10"
                  y="11"
                  width="52"
                  height="51"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="8" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.0392157 0 0 0 0 0.219608 0 0 0 0.16 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_315_15648"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_315_15648"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            {isLive && (
              <svg
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={twMerge(
                  'svg-two absolute top-0 left-0 z-10',
                  styles['svg-two'],
                )}
              >
                <g filter="url(#filter0_d_315_15649)">
                  <circle cx="36" cy="34" r="20" fill="#C3CAEE" />
                </g>
                <circle cx="36" cy="34" r="16" fill="#0522BA" />
                <g filter="url(#filter1_d_315_15649)">
                  <path
                    d="M27.7003 25.1517V33.1676H26V25.1517H27.7003ZM30.9436 26.7765H26.1574V25.1517H30.9436V26.7765ZM30.4293 30.0371H26.1155V28.4555H30.4293V30.0371Z"
                    fill="white"
                  />
                  <path
                    d="M31.2264 30.4487C31.2264 29.8782 31.3488 29.3799 31.5937 28.9538C31.8456 28.5205 32.1885 28.1847 32.6223 27.9464C33.0632 27.7081 33.5635 27.5889 34.1232 27.5889C34.683 27.5889 35.1798 27.7081 35.6137 27.9464C36.0475 28.1847 36.3869 28.5205 36.6318 28.9538C36.8837 29.3799 37.0096 29.8782 37.0096 30.4487C37.0096 31.0192 36.8837 31.5175 36.6318 31.9436C36.3869 32.3696 36.0475 32.7018 35.6137 32.9401C35.1798 33.1784 34.683 33.2976 34.1232 33.2976C33.5635 33.2976 33.0632 33.1784 32.6223 32.9401C32.1885 32.7018 31.8456 32.3696 31.5937 31.9436C31.3488 31.5175 31.2264 31.0192 31.2264 30.4487ZM32.8532 30.4379C32.8532 30.7123 32.9057 30.9542 33.0107 31.1636C33.1226 31.3658 33.2731 31.5247 33.462 31.6403C33.6509 31.7486 33.8713 31.8027 34.1232 31.8027C34.3751 31.8027 34.5956 31.7486 34.7845 31.6403C34.9734 31.5247 35.1204 31.3658 35.2253 31.1636C35.3303 30.9542 35.3828 30.7123 35.3828 30.4379C35.3828 30.1634 35.3303 29.9251 35.2253 29.7229C35.1204 29.5207 34.9734 29.3654 34.7845 29.2571C34.5956 29.1416 34.3751 29.0838 34.1232 29.0838C33.8713 29.0838 33.6509 29.1416 33.462 29.2571C33.2731 29.3654 33.1226 29.5207 33.0107 29.7229C32.9057 29.9251 32.8532 30.1634 32.8532 30.4379Z"
                    fill="white"
                  />
                  <path
                    d="M37.6633 30.4487C37.6633 29.8782 37.7858 29.3799 38.0307 28.9538C38.2826 28.5205 38.6254 28.1847 39.0593 27.9464C39.5001 27.7081 40.0004 27.5889 40.5602 27.5889C41.12 27.5889 41.6168 27.7081 42.0506 27.9464C42.4844 28.1847 42.8238 28.5205 43.0687 28.9538C43.3206 29.3799 43.4465 29.8782 43.4465 30.4487C43.4465 31.0192 43.3206 31.5175 43.0687 31.9436C42.8238 32.3696 42.4844 32.7018 42.0506 32.9401C41.6168 33.1784 41.12 33.2976 40.5602 33.2976C40.0004 33.2976 39.5001 33.1784 39.0593 32.9401C38.6254 32.7018 38.2826 32.3696 38.0307 31.9436C37.7858 31.5175 37.6633 31.0192 37.6633 30.4487ZM39.2902 30.4379C39.2902 30.7123 39.3427 30.9542 39.4476 31.1636C39.5596 31.3658 39.71 31.5247 39.8989 31.6403C40.0879 31.7486 40.3083 31.8027 40.5602 31.8027C40.8121 31.8027 41.0325 31.7486 41.2214 31.6403C41.4103 31.5247 41.5573 31.3658 41.6622 31.1636C41.7672 30.9542 41.8197 30.7123 41.8197 30.4379C41.8197 30.1634 41.7672 29.9251 41.6622 29.7229C41.5573 29.5207 41.4103 29.3654 41.2214 29.2571C41.0325 29.1416 40.8121 29.0838 40.5602 29.0838C40.3083 29.0838 40.0879 29.1416 39.8989 29.2571C39.71 29.3654 39.5596 29.5207 39.4476 29.7229C39.3427 29.9251 39.2902 30.1634 39.2902 30.4379Z"
                    fill="white"
                  />
                  <path d="M46 33.1676H44.3836V25H46V33.1676Z" fill="white" />
                  <path
                    d="M35.3064 43.9783L29.7646 44V42.7001L32.3256 40.5011C32.5355 40.3278 32.7139 40.1653 32.8609 40.0137C33.0078 39.8548 33.1268 39.7032 33.2177 39.5587C33.3157 39.4071 33.3857 39.259 33.4276 39.1146C33.4766 38.9629 33.5011 38.8041 33.5011 38.638C33.5011 38.2336 33.4067 37.9266 33.2177 37.7172C33.0358 37.5006 32.7699 37.3922 32.42 37.3922C32.0562 37.3922 31.7728 37.515 31.5699 37.7605C31.374 38.0061 31.276 38.3527 31.276 38.8005H29.5862C29.5862 38.2011 29.7016 37.6811 29.9325 37.2406C30.1704 36.7929 30.4993 36.4462 30.9191 36.2007C31.346 35.9551 31.8428 35.8324 32.4095 35.8324C33.0043 35.8324 33.5116 35.9407 33.9314 36.1574C34.3513 36.3668 34.6732 36.6737 34.8971 37.0781C35.128 37.4753 35.2434 37.9591 35.2434 38.5296C35.2434 38.8041 35.2049 39.0677 35.128 39.3204C35.051 39.5659 34.9426 39.8043 34.8026 40.0353C34.6627 40.2664 34.4947 40.4903 34.2988 40.707C34.1029 40.9236 33.8825 41.1366 33.6376 41.3461L31.9162 42.8301L31.2865 42.3535H35.3064V43.9783Z"
                    fill="#FFCC32"
                  />
                  <path
                    d="M41.3045 35.984V44H39.6986V35.984H41.3045ZM35.8361 41.0428L39.1843 35.984H40.9791L37.5575 41.1727L35.8361 41.0428ZM42.2596 42.581H35.8361V41.0428H42.2596V42.581Z"
                    fill="#FFCC32"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_315_15649"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.890196 0 0 0 0 0.109804 0 0 0 0 0.156863 0 0 0 0.6 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_315_15649"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_315_15649"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_315_15649"
                    x="10"
                    y="11"
                    width="52"
                    height="51"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.00784314 0 0 0 0 0.0392157 0 0 0 0 0.219608 0 0 0 0.16 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_315_15649"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_315_15649"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}
          </div>
        </button>
      </Link>
    </div>
  )
}
