import {Transition} from '@headlessui/react'

interface RouteTransitionOverlayProps {
  show: boolean
}

export function RouteTransitionOverlay({show}: RouteTransitionOverlayProps) {
  return (
    <Transition
      show={show}
      as="div"
      className="absolute inset-0 w-full h-full bg-primary-0 opacity-0 z-50"
      enter="transition-opacity ease-in-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-40"
      leave="transition-opacity ease-in-out duration-300"
      leaveFrom="opacity-40"
      leaveTo="opacity-0"
    />
  )
}
