'use client'

import {useRouter} from 'next/navigation'
import {useEffect, useTransition} from 'react'
import {RouteTransitionOverlay} from './route-transition-overlay'

export function RouteChangeListener() {
  const router = useRouter()

  const [isPending, startTransition] = useTransition()
  useEffect(() => {
    // Temporary monkeypatch for Next.js App Router's lack of navigation events.
    // Patches router.push for immediate route transition feedback.
    // Can be removed once Next.js restores router.events or provides an
    // alternative API.
    // Based on https://github.com/vercel/next.js/discussions/41934
    if (router.push.name === 'patched') {
      return
    }
    const push = router.push
    router.push = function patched(...args) {
      startTransition(() => push.apply(history, args))
    }
  }, [])

  return <RouteTransitionOverlay show={isPending} />
}
