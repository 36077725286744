import {JesterSvg} from '@fool/jester-ui/JesterHat/JesterSvg'
import Image from 'next/image'

type AvatarProps = {
  altText: string
  userImage?: string
  size?: number
}

function AvatarBasic({altText, userImage, size = 40}: AvatarProps) {
  return (
    <>
      {userImage && (
        <span
          className="flex rounded-full overflow-hidden"
          style={{height: `${size}px`, width: `${size}px`}}
        >
          <Image
            className="object-cover"
            src={userImage}
            width={size}
            height={size}
            alt={altText}
          />
        </span>
      )}
      {!userImage && (
        <svg viewBox="0 0 10 10" x="4" width={size} aria-labelledby={altText}>
          <circle cx="5" cy="5" r="5" fill="#C3CAEE" x="6" y="6" />
          <JesterSvg />
        </svg>
      )}
    </>
  )
}

export default AvatarBasic
