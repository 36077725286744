'use client'
import {Fragment, useEffect, useState, useRef, useCallback} from 'react'
import {FoolSession} from '~auth/options.ts'
import {signOut, useSession} from 'next-auth/react'
import {Menu, Transition} from '@headlessui/react'
import Avatar from '~components/jester-temp/modified/Avatar'
import Divider from '@fool/jester-ui/Divider'
import RewardsIcon from '~components/nav-icons/rewards'
import {ContactIcon, LogOutIcon, SettingsIcon} from '@fool/jester-ui/Icon'
import MemberBenefitsIcon from '~components/nav-icons/member-benefits.tsx'
import classNames from 'classnames'

import Cookies from 'js-cookie'
import {
  getLastauthCookieKey,
  FOOL_AUTH_COOKIE_DOMAIN,
  FOOL_AUTH_COOKIE_PATH,
  update_current_sso_protected,
} from '~auth/fool-auth-utils'
import {getUserTier} from '~data/api/tiers'
import {Link} from '~components/common/link'

const mySettingsIcon = <SettingsIcon />
const myContactIcon = <ContactIcon />
const myRewardsIcon = <RewardsIcon />
const myMemberBenefitsIcon = <MemberBenefitsIcon />

const userNavigation = [
  {
    name: 'Member Benefits',
    icon: myMemberBenefitsIcon,
    href: '/member-benefits/',
  },
  {
    name: 'Earn Rewards',
    icon: myRewardsIcon,
    href: 'https://www.fool.com/account/rewards',
  },
  {
    name: 'Account',
    icon: mySettingsIcon,
    href: '/account/settings/',
  },
  {
    name: 'Contact us',
    icon: myContactIcon,
    href: '/contact/',
  },
]

function ProfileDropdown() {
  const [isClient, setIsClient] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  // TO DO. Use prop instead of session data
  const session = useSession()
  const foolSession = useSession()?.data as FoolSession | null
  const [isPending, setIsPending] = useState(false)
  const userTier = getUserTier({session: session.data})
  const tier = (userTier.tier || 1) as 1 | 2 | 3 | 4 | 5 | null
  const avatarUrl =
    session?.data?.user?.image ||
    (foolSession?.foolUid &&
      `https://g.foolcdn.com/avatar/${foolSession.foolUid}/large.ashx`)

  const deleteLastauthCookie = () => {
    Cookies.remove(getLastauthCookieKey(), {
      path: FOOL_AUTH_COOKIE_PATH,
      domain: FOOL_AUTH_COOKIE_DOMAIN,
    })
  }

  // Render the default avatar on server in case the page is being edge cached
  useEffect(() => setIsClient(true))

  const closeMenu = useCallback(() => {
    menuButtonRef.current?.click()
  }, [])

  return userTier && userTier.tier !== 0 ? (
    <Menu as="div" className="relative flex-shrink-0">
      <div>
        <Menu.Button
          ref={menuButtonRef}
          className={classNames(
            'relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            isPending && 'opacity-70 cursor-wait',
          )}
        >
          <span className="absolute -inset-1.5" />
          <span className="sr-only"></span>
          <span suppressHydrationWarning>
            {isClient ? (
              <Avatar
                altText="open user menu"
                userImage={avatarUrl}
                tier={tier}
              />
            ) : (
              <Avatar altText="open user menu" />
            )}
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute rounded-lg shadow bg-primary-0 px-8px pt-16px pb-2 whitespace-nowrap z-10 -top-[16px] -right-[8px]">
          <div className="flex items-center pb-16px">
            <div className="w-full">
              <p className="text-paragraph-bold pl-8px">{userTier.name}</p>
            </div>
            <div className="h-auto">
              <Menu.Button
                className={classNames(
                  'relative flex rounded-full bg-white bg-white transition-all duration-100 hover:shadow-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                  isPending && 'opacity-70 cursor-wait',
                )}
              >
                <Avatar altText="avatar" userImage={avatarUrl} tier={tier} />
              </Menu.Button>
            </div>
          </div>

          <Divider className="bg-primary-8"></Divider>

          {userNavigation.map((item) => (
            <Fragment key={item.name}>
              <Menu.Item>
                {({active}) => {
                  return (
                    <div
                      onClick={(e) => {
                        // Stop headless-ui Menu.Item from handling the click so
                        // we can provide proper route transition UX.
                        e.stopPropagation()
                      }}
                    >
                      <Link
                        href={item.href}
                        className={classNames(
                          active
                            ? 'bg-primary-4 rounded-lg transition ease-in-out duration-300 transform'
                            : '',
                          'block',
                        )}
                        setIsPending={setIsPending}
                        onClick={(e) => {
                          // Prevent the Menu from immediately closing during
                          // route transition.
                          e.stopPropagation()
                        }}
                        onNavigationComplete={closeMenu}
                      >
                        <div className="flex items-center p-8px gap-8px">
                          <div className="flex items-center">{item.icon}</div>
                          <div className="text-body-regular font-bold">
                            <div className="block">{item.name}</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                }}
              </Menu.Item>
            </Fragment>
          ))}

          <Divider className="bg-primary-8"></Divider>

          <a
            className="block rounded-lg hover:bg-primary-4 active:bg-primary-4 focus:bg-primary-4 hover:cursor-pointer active:cursor-pointer focus:cursor-pointer transition ease-in-out duration-300 transform"
            onClick={() => {
              deleteLastauthCookie()

              // make sure we call this only from the client side!
              if (window) {
                update_current_sso_protected(window.location.href)
              }

              signOut({callbackUrl: '/premium/api/auth/auth0-logout/'})
            }}
          >
            <div className="flex items-center gap-8px pl-8px py-8px text-primary-100">
              <LogOutIcon />
              <div className="font-bold">Log out</div>
            </div>
          </a>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null
}

export default ProfileDropdown
