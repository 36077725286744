export default function MemberBenefitsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 22.3193H15.1274C15.6979 22.3193 16.2573 22.161 16.7431 21.862L21.3142 19.0491C21.9507 18.6573 22.113 17.8034 21.6646 17.2054V17.2054C21.2838 16.6977 20.5866 16.549 20.0318 16.8572L16.6298 18.7472C16.5447 18.7945 16.4489 18.8193 16.3515 18.8193H15M11.5 18.8193H15M3 18.3193H5.5C5.5 18.3193 7.50158 16.802 9 16.3193C11.2303 15.6008 15 16.3193 15 16.3193C16.2642 17.1697 16.21 17.7254 15 18.8193"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 8C16.5 10.4853 14.4853 12.5 12 12.5C9.51472 12.5 7.5 10.4853 7.5 8C7.5 5.51472 9.51472 3.5 12 3.5C14.4853 3.5 16.5 5.51472 16.5 8ZM18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12.75 4.75C12.75 4.33579 12.4142 4 12 4C11.5858 4 11.25 4.33579 11.25 4.75V4.87839C10.5094 5.11657 9.75 5.72644 9.75 6.70843C9.75 7.03242 9.82643 7.33677 9.99398 7.60326C10.159 7.86568 10.3823 8.04374 10.6022 8.16694C10.9791 8.37813 11.4453 8.47522 11.7774 8.54438L11.8457 8.55865C12.2507 8.64387 12.4906 8.70579 12.6419 8.79767C12.7004 8.83324 12.7173 8.85795 12.7235 8.86841C12.7286 8.87718 12.75 8.91576 12.7499 9.02061C12.7499 9.29688 12.6694 9.45589 12.5904 9.54719C12.508 9.64251 12.3843 9.71357 12.22 9.73963C11.8824 9.79322 11.4221 9.64161 11.1294 9.19061C10.9039 8.84315 10.4395 8.74426 10.092 8.96974C9.74455 9.19522 9.64566 9.65968 9.87114 10.0071C10.2214 10.5468 10.7151 10.9171 11.25 11.1055V11.25C11.25 11.6642 11.5858 12 12 12C12.4142 12 12.75 11.6642 12.75 11.25V11.1538C13.115 11.0441 13.4569 10.8384 13.725 10.5284C14.0679 10.1319 14.2499 9.61171 14.2499 9.02085C14.25 8.69212 14.1777 8.38415 14.0188 8.11202C13.8609 7.84159 13.6435 7.65109 13.4208 7.51577C13.0208 7.27272 12.5273 7.16909 12.1832 7.09682L12.1545 7.09079C11.7385 7.00327 11.4944 6.94744 11.3354 6.85836C11.3044 6.84101 11.2862 6.82707 11.2763 6.81816C11.267 6.80994 11.2643 6.80557 11.2639 6.80488L11.2638 6.80486L11.2638 6.80482C11.2635 6.80433 11.2611 6.80063 11.2582 6.78985C11.255 6.77788 11.25 6.75241 11.25 6.70843C11.25 6.62567 11.3423 6.38733 11.794 6.28289C12.2031 6.18829 12.6489 6.29269 12.9328 6.62087C13.2037 6.93414 13.6774 6.96843 13.9907 6.69745C14.3039 6.42647 14.3382 5.95284 14.0672 5.63956C13.694 5.20804 13.2289 4.95127 12.75 4.83185V4.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
