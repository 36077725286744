'use client'

import {useEffect, useCallback} from 'react'

export function useOffsetAnchorLinks(offset: number = 80): void {
  const handleAnchorClick = useCallback(
    (e: Event): void => {
      const target = e.target as HTMLAnchorElement
      if (
        target.tagName === 'A' &&
        target.getAttribute('href')?.startsWith('#')
      ) {
        const targetId = target.getAttribute('href')?.slice(1)
        if (targetId) {
          e.preventDefault()
          const targetElement = document.getElementById(targetId)
          if (targetElement) {
            const elementPosition = targetElement.getBoundingClientRect().top
            const offsetPosition = elementPosition + window.scrollY - offset

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            })
          }
        }
      }
    },
    [offset],
  )

  useEffect(() => {
    document.addEventListener('click', handleAnchorClick)

    return () => {
      document.removeEventListener('click', handleAnchorClick)
    }
  }, [handleAnchorClick])
}
