'use client'
import type {LinkProps} from 'next/link'
import {Link} from '~components/common/link'
import {useCallback, memo} from 'react'
import type {PropsWithChildren, MouseEvent} from 'react'
import {pandoCanHandle, getPandoPath} from './pando-utils'
import {twMerge} from 'tailwind-merge'
import {trackEvent} from 'src/app/(main)/tracking/infotrack'
import {useRouter} from 'next/navigation'

// This worked for either instrumentId EXCLUSIVE OR exchange and symbol
// but requires most uses to do stuff like symbol={symbol || ''} since instrument, symbol, exchange are often nullable
// type InstrumentOrExchangeSymbol = {}
//   | {instrumentId: number; exchange?: never; symbol?: never}
//   | {instrumentId?: never; exchange: string; symbol: string}

type InstrumentOrExchangeSymbol = {
  instrumentId?: number | null | undefined
  exchange?: string | null | undefined
  symbol?: string | null | undefined
  className?: string
  gaTrackingEventName?: string
  gaCategory?: string | null | undefined
  gaLabel?: string
  gaAdditionalProperties?: object
}

type CompanyLink = Omit<LinkProps, 'href'>

export type CompanyLinkProps = InstrumentOrExchangeSymbol &
  JSX.IntrinsicElements['a'] &
  CompanyLink

const CompanyLink = ({
  children,
  instrumentId,
  exchange,
  symbol,
  className = '',
  target = '',
  gaTrackingEventName = 'Premium_click',
  gaCategory,
  gaLabel,
  gaAdditionalProperties,
}: PropsWithChildren<CompanyLinkProps>) => {
  const router = useRouter()

  // For now, any instrumentId gets redirected to /company/instrumentId to handle redirect to pando.
  const goToPando = !instrumentId && pandoCanHandle(exchange || '')

  const classes = twMerge(
    'text-primary-100 hover:text-primary-120 focus:text-primary-120 font-bold',
    className,
  )

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>, href: string, label: string) => {
      if (gaCategory) {
        if (gaAdditionalProperties) {
          trackEvent(gaTrackingEventName, gaCategory, gaLabel || label, {
            href,
            ...gaAdditionalProperties,
          })
        } else {
          trackEvent(gaTrackingEventName, gaCategory, gaLabel || label)
        }
      }
      router.push(href)
    },
    [gaCategory, gaTrackingEventName, gaLabel, router],
  )

  return (
    <>
      {instrumentId && (
        <Link
          href={`/company/${instrumentId}`}
          target={`${target}`}
          onClick={(e) => {
            e.preventDefault()
            handleClick(
              e,
              `/company/${instrumentId}`,
              `{Symbol undefined. Instrument id: ${instrumentId}}`,
            )
          }}
          className={classes}
        >
          {children}
        </Link>
      )}
      {!goToPando && exchange && symbol && (
        <Link
          href={`/company/${exchange}/${symbol}`}
          onClick={(e) => {
            e.preventDefault()
            handleClick(e, `/company/${exchange}/${symbol}`, symbol)
          }}
          className={classes}
        >
          {children}
        </Link>
      )}
      {/* {goToPando && instrumentId && (
        <Link
          href={`${pandoRoot}/company/${instrumentId}/financials/summary`}
          target={`${target}`}
          onClick={onClickHander}
          className={anchorClasses}
        >
          {children}
        </Link>
      )} */}
      {goToPando && symbol && (
        <Link
          href={getPandoPath(undefined, exchange, symbol)}
          target={`${target}`}
          onClick={(e) => {
            e.preventDefault()
            handleClick(e, getPandoPath(undefined, exchange, symbol), symbol)
          }}
          className={classes}
        >
          {children}
        </Link>
      )}
    </>
  )
}

export default memo(CompanyLink)
