import AvatarTiers from './AvatarTiers'
import AvatarBasic from './AvatarBasic'

/**
 * TODO: Current jester-ui@1.1.0 build is having issues resolving
 * Image from next/image causing:
 *   "Element type is invalid: expected a string
 *   (for built-in components) or a class/function but got: object"
 * This will need to be revisited.
 */

type AvatarProps = {
  altText: string
  tier?: 1 | 2 | 3 | 4 | 5 | null
  userImage?: string
}

function Avatar({altText, tier, userImage}: AvatarProps) {
  return (
    <>
      {tier && (
        <AvatarTiers tier={tier} userImage={userImage} altText={altText} />
      )}
      {!tier && <AvatarBasic userImage={userImage} altText={altText} />}
    </>
  )
}

export default Avatar
