import {ReadonlyHeaders} from 'next/dist/server/web/spec-extension/adapters/headers'
import {validate as uuidValidate} from 'uuid'

/**
 * Get the name of the lastauth cookie
 * depending on environment.
 *
 * @returns {string} - lastauth cookie name
 */
export function getLastauthCookieKey() {
  const lastauthStaging = 'lastauth-staging'
  const lastauthProd = 'lastauth'
  const cookieName = process.env.NEXT_PUBLIC_AUTH0_ISSUER?.includes('staging')
    ? lastauthStaging
    : lastauthProd
  return cookieName
}

export const getAuthPath = () => {
  const isFeature =
    process.env.NEXT_PUBLIC_FOOL_ENV !== 'production' &&
    process.env.NEXT_PUBLIC_FOOL_ENV !== 'staging'
  return isFeature
    ? process.env.NEXTAUTH_URL
    : process.env.NEXT_PUBLIC_NEXTAUTH_URL
}

/**
 * Returns the timestamp in seconds representing
 * the last time a user was authenticated.
 *
 * @returns {number} - timestamp in seconds
 */
export function getLastAuthCookieValue() {
  return String(Math.round(Date.now() / 1000))
}

/**
 * Construct the Auth0 logout url in order to log the user
 * out of Auth0 and end their Auth0 session, i.e.
 * log out of the identity provider.
 *
 * @returns {string} - logout url
 */
export function getAuth0LogoutUrl() {
  const homepageUrl = String(process.env.NEXTAUTH_URL).split('/api/auth')[0]
  const returnUrl = encodeURI(homepageUrl)

  return `${process.env.NEXT_PUBLIC_AUTH0_ISSUER}/v2/logout?client_id=${process.env.AUTH0_CLIENT_ID}&returnTo=${returnUrl}`
}

// the domain and path we write lastauth cookies to
export const FOOL_AUTH_COOKIE_DOMAIN = '.fool.com'
export const FOOL_AUTH_COOKIE_PATH = '/'

export const CHANGE_PASSWORD_MODE = 'forgot-password'
export const FORCE_LOGIN_PROMPT = 'login'
export const SILENT_AUTH_PROMPT = 'none'
export const EMLPOYEE_LOGIN_CONNECTION = 'tmf-okta-sso'
export const ORDER_AUTH_COOKIE_KEY = 'orderauth'

// set max age of 30 days
export const LAST_AUTH_COOKIE_MAX_AGE = 30 * 24 * 60 * 60

// name of SSO field to set in Nextjs session
export const SSO_SESSION_NUMBER_KEY = 'auth_sso_session_number'

// name of current SSO field to expect from an API response
export const CURRENT_SSO_RESPONSE_KEY = 'current_sso_session_number'

interface currentSSOJsonPayload {
  [CURRENT_SSO_RESPONSE_KEY]: number | string
  uid: number | null
}

/**
 * Parse the current SSO field from an API response to an integer.
 *
 * API calls to fetch the current SSO session should always return a
 * payload with two fields, `current_sso_session_number` and `uid`,
 * so we're just making sure we cast the current SSO field to an integer.
 *
 * @returns {Object} - the original response but with the SSO field as an integer.
 */
const parseSSO = (data: currentSSOJsonPayload) => {
  return {
    ...data,
    [CURRENT_SSO_RESPONSE_KEY]:
      typeof data[CURRENT_SSO_RESPONSE_KEY] == 'string'
        ? parseInt(data[CURRENT_SSO_RESPONSE_KEY])
        : data[CURRENT_SSO_RESPONSE_KEY],
  }
}

// dummy value to use for the current SSO session number if calls to fool-auth Redis fails
export const DUMMY_SSO_SESSION_NUMBER = -1

/**
 * Return a dummy API response if there are errors calling to fool-auth Redis.
 *
 * The API response must conform to the `currentSSOJsonPayload`, i.e. have two
 * keys, `current_sso_session_number` and `uid`.
 *
 * In the event that there are errors calling to Redis, we do not want the
 * site to be inaccessible to members. It is possible that this dummy value
 * gets set on initial login or is read during the middleware check.
 *
 * However, whenever Redis errors cease and we start fetching the correct current SSO
 * session value once again, the middleware checks will naturally fix and reset any
 * dummy SSO values we are holding onto.
 *
 * @returns {currentSSOJsonPayload} - the original response but with the SSO field as an integer.
 */
export const getDummySSOResponse = () => {
  return {
    [CURRENT_SSO_RESPONSE_KEY]: DUMMY_SSO_SESSION_NUMBER,
    uid: null,
  }
}

/**
 * Wrapper to fetch the current SSO session number from fool-auth Redis.
 *
 * This should only be used when setting the SSO session number
 * on login!
 *
 * Requires passing in the uid value, since we can't derive it
 * from the session.
 *
 * Reason: During login, the session isn't created yet, so we cannot
 * get the user's uid from it, nor check for an active session to prevent
 * unauthenticated requests, hence this route is "unprotected",
 * because anyone can call this if they know the route!
 *
 * @param {number} foolUid - a user's uid
 * @returns {currentSSOJsonPayload} - response data
 */
export async function get_current_sso_unprotected(foolUid: number | undefined) {
  const url = `${getAuthPath()}/unprotected-current-sso-session-number?foolUid=${foolUid}`

  try {
    const response = await fetch(url)
    const data = await response.json()
    try {
      return parseSSO(data)
    } catch (error) {
      const text = (error as {cause?: string}).cause ?? error
      console.log(
        `❌ Error parsing current SSO at ${url} (get_current_sso_unprotected) - ${String(
          text,
        )}`,
      )
    }
  } catch (error) {
    const text = (error as {cause?: string}).cause ?? error
    console.log(
      `❌ Error getting current SSO at ${url} (get_current_sso_unprotected) - ${String(
        text,
      )}`,
    )
  }
  return getDummySSOResponse()
}

/**
 * Wrapper to fetch the current SSO session number from fool-auth Redis.
 *
 * This is used when looking up the current SSO number within
 * the middleware, where we know the user has an active session.
 *
 * We need to pass the NextJS headers because without it, we cannot
 * check the session within the route handler. Passing the headers
 * mimics a request from the client which includes the NextJS
 * session cookies. With the headers, we are able to check
 * the active session within the route handler.
 *
 * If an error occurs, return a dummy response with -1 as the current SSO session
 * number. This value could get set in the app session, depending on when the error
 * occurs. If the errors happen during the middleware checks, the comparison
 * local/current SSO check is skipped. Eventually when a successful fetch occurs,
 * the middleware will trigger a silent auth and update any dummy values stored
 * in the app session.
 *
 * @param {any} headers - NextJS headers
 * @returns {currentSSOJsonPayload} - response data
 */
export async function get_current_sso_protected(headers: ReadonlyHeaders) {
  const url = `${getAuthPath()}/current-sso-session-number`
  // we need to strip away content-length headers on any PUT or POST calls instead of passing them through to the backend
  // otherwise this call fails since the content-length won't match the non body of the GET
  const newHeaders = new Headers()
  headers.forEach((headerValue: string, headerKey: string) => {
    if (headerKey.toLowerCase() !== 'content-length') {
      newHeaders.append(headerKey, headerValue)
    }
  })
  try {
    const response = await fetch(url, {method: 'GET', headers: newHeaders})
    if (response.status != 200) {
      throw new Error(
        `Error fetching current SSO session number - ${response.status}: ${response.statusText}`,
      )
    }
    const data = await response.json()
    try {
      return parseSSO(data)
    } catch (error) {
      const text = (error as {cause?: string}).cause ?? error
      console.log(
        `❌ Error parsing current SSO at ${url} (get_current_sso_protected) - ${String(
          text,
        )}`,
      )
    }
  } catch (error) {
    const text = (error as {cause?: string}).cause ?? error
    console.log(
      `❌ Error getting current SSO at ${url} (get_current_sso_protected) - ${String(
        text,
      )}`,
    )
  }
  return getDummySSOResponse()
}

/**
 * Wrapper to update the current SSO session number in fool-auth Redis.
 *
 * This is used when a user is logging out and need to increment
 * their SSO session number which will trigger a global logout to
 * other sites.
 *
 * Note: We don't need to pass NextJS headers here because this gets called
 * from the client side where the headers are sent implicitly it seems,
 * therefore we can still access the user's session right before it gets
 * terminated before a logout.
 *
 * If an error occurs making the request, return null.
 * The side effect of not incrementing the current SSO session number
 * on logout is that global logout isn't triggered, which means
 * even though a member's Auth0 session has ended, they are still logged
 * into an app and might see weird behavior until their JWT expires.
 *
 * @param {string} siteUrl - current site url
 * @returns {null}
 */
export async function update_current_sso_protected(siteUrl: string) {
  const url = `${siteUrl}/api/auth/current-sso-session-number`
  try {
    await fetch(url, {method: 'PUT'})
  } catch (error) {
    // console.logs will show up in browser, so just return
    return
  }
}

// name of "auth down" field to expect from an API response
export const AUTH_DOWN_RESPONSE_KEY = 'is_auth_provider_down'

/**
 * Wrapper to get the current "auth down" flag in fool-auth Redis.
 *
 * This is used in a check when a user is about to login.
 * If the response indicates that our auth provider is down,
 * an error page is shown to the user letting them know things
 * are down and to try again soon, and their lastauth cookie
 * is deleted to prevent silent auths happening during an outage.
 *
 * If there is an error, return false, i.e.
 * assume there is no auth outage. The side effect is that if there
 * is an outage while we encounter an error, the user gets
 * redirected to Auth0 and might see strange behavior.
 *
 * @returns {Boolean}
 */
export async function is_auth_provider_down() {
  const url = `${getAuthPath()}/get-auth-down-flag`
  try {
    const response = await fetch(url)
    const data = await response.json()
    return data[AUTH_DOWN_RESPONSE_KEY]
  } catch (error) {
    const text = (error as {cause?: string}).cause ?? error
    console.log(`❌ Error getting auth down flag at ${url} - ${String(text)}`)
    return false
  }
}

/**
 * Runs a couple checks to see if a user qualifies for
 * on order auth challenge and returns true or false.
 *
 * Users might be redirected to this site after a purchase
 * in an unauthenticated state and need to go back to
 * Commerce-UI to either set a password or log in via OTP.
 *
 * The original check implemented in the now deprecated
 * FFE codebase included a check to see the request is AJAX
 * or an auth url. Not sure if we need these checks here.
 *
 * @param {object} query_params
 * @param {string | undefined} orderauth_cookie_value
 * @returns {Boolean}
 */
export function qualifies_for_order_auth_challenge(
  query_params: object,
  orderauth_cookie_value: string | undefined,
) {
  // If user already has these query params,
  // they are already in an auth challenge, so return false
  if (['idh', 'mode_type'].every((param) => param in query_params)) return false

  // Check if `orderauth` cookie exists with a valid value;
  // Value is the user's identity-hint, a uuid associated with their email.
  return orderauth_cookie_value ? uuidValidate(orderauth_cookie_value) : false
}
