'use client'
import {
  Fragment,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  memo,
} from 'react'
import {Link} from '~components/common/link'
import Skeleton from 'react-loading-skeleton'
import {useRouter, useParams} from 'next/navigation'
import {Combobox, Transition} from '@headlessui/react'
import {useLazyQuery} from '@apollo/client'
import Button from '@fool/jester-ui/Button'
import Divider from '@fool/jester-ui/Divider'
import Heading from '@fool/jester-ui/Heading'
import {SearchInput} from '@fool/jester-ui/SearchInput'
import PerformanceChip from '@fool/jester-ui/PerformanceChip'
import {
  SearchIcon,
  NewsIcon,
  RecsIcon,
  PlayIcon,
  ChevronRightIcon,
} from '@fool/jester-ui/Icon'
import {GetSearchResultsQuery} from '~types/__generated__/graphql'
import CompanyImage from '~components/common/company-image'
import {SEARCH_QUERY} from '~data/api/search.ts'
import CompanyLink from '~components/common/links/company-link'
import {trackEvent} from 'src/app/(main)/tracking/infotrack'
import {debounce} from 'lodash'
import FocusTrap from 'focus-trap-react'
import {useSession} from 'next-auth/react'
import {getAuthPath, SILENT_AUTH_PROMPT} from 'src/auth/fool-auth-utils'
import classNames from 'classnames'

const LIMIT = 3
const DAYS_AGO = 90
const GET_SEARCH_RESULTS = SEARCH_QUERY
const LOCAL_STORAGE_KEY = 'recent-searches'
const DEBOUNCE_DELAY = 300
const GA_TRACKING_EVENT_NAME = 'Premium_search'
const GA_TRACKING_CATEGORY = 'Search'

type HeaderSearchProps = {
  customClasses?: string
}

const LoadingSkeleton = memo(function LoadingSkeleton() {
  const loadingArray = Array.from({length: 3}, (_, i) => i)
  return (
    <>
      {loadingArray.map((_, index) => (
        <li
          key={`loader-${index}`}
          className="flex items-center rounded-[8px] px-8px py-4px gap-8px"
        >
          <Skeleton
            baseColor="#EBEDF9"
            highlightColor="#F5F6FC"
            width={32}
            height={32}
            borderRadius={32}
          />
          <Skeleton
            baseColor="#EBEDF9"
            highlightColor="#F5F6FC"
            width={300}
            height={20}
            borderRadius={20}
          />
          <Skeleton
            baseColor="#EBEDF9"
            highlightColor="#F5F6FC"
            width={80}
            height={20}
            borderRadius={20}
            className="ml-auto"
          />
        </li>
      ))}
    </>
  )
})

export default function HeaderSearch({customClasses = ''}: HeaderSearchProps) {
  const [searchQuery, {loading}] = useLazyQuery(GET_SEARCH_RESULTS)

  const [query, setQuery] = useState<string>('')
  const [queryResults, setQueryResults] = useState<GetSearchResultsQuery>()
  const router = useRouter()
  const params = useParams()
  // TODO: use next/auth session.update() instead of silent-auth redirect when
  // we figure out how to make it work.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {status: sessionStatus, update: updateSession} = useSession()

  useEffect(() => {
    document.querySelector('body')?.classList.remove('cursor-wait')
  }, [params])

  const abortControllerRef = useRef<AbortController | null>(null)

  const debouncedSearch = useMemo(() => {
    return debounce((term: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      abortControllerRef.current = new AbortController()

      searchQuery({
        variables: {
          limit: LIMIT,
          daysAgo: DAYS_AGO,
          searchTerm: term,
        },
        context: {
          fetchOptions: {
            signal: abortControllerRef.current.signal,
          },
        },
      })
        .then(({data}: {data: GetSearchResultsQuery}) => {
          if (data) setQueryResults(data)
          abortControllerRef.current = null
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error(error)
          }
          abortControllerRef.current = null
        })
    }, DEBOUNCE_DELAY)
  }, [searchQuery])

  const debouncedPrefetch = useMemo(() => {
    return debounce((term: string) => {
      router.prefetch(`/search/?q=${term}`)
    }, DEBOUNCE_DELAY * 1.1)
  }, [router])

  useEffect(() => {
    if (query) {
      debouncedSearch(query)
      if (query.length > 2) {
        debouncedPrefetch(query)
      }
    }

    return () => {
      debouncedSearch.cancel()
      debouncedPrefetch.cancel()
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }
    }
  }, [query, debouncedSearch, debouncedPrefetch])

  type SearchLinkProps = {
    refValue: string
    active: boolean
    searchTerm: string
    linkType:
      | 'company'
      | 'news'
      | 'recommendation'
      | 'video'
      | 'recent'
      | 'viewall'
    href?: string
    exchange?: string
    symbol?: string
    setIsPending?: (isPending: boolean) => void
  }

  const SearchLink = ({
    refValue,
    active,
    searchTerm,
    href,
    exchange,
    symbol,
    linkType,
    ...props
  }: PropsWithChildren<SearchLinkProps>) => {
    const isCompanyLink = exchange && symbol
    const handleClick = () => {
      if (searchTerm) {
        trackEvent(GA_TRACKING_EVENT_NAME, GA_TRACKING_CATEGORY, searchTerm, {
          page_type: 'premium',
          link_type: linkType,
          href,
        })
        updateRecentSearchesArray(searchTerm)
      }
      setDropdownActive(false)
      setQuery('')
    }
    const classes = `flex items-center rounded-[8px] px-8px py-4px gap-8px ${
      active ? 'bg-primary-4' : ''
    }`

    return (
      <>
        {!isCompanyLink && (
          <Link
            {...props}
            ref={(link: HTMLAnchorElement | null): void => {
              itemsRef.current[refValue] = link
            }}
            onClick={handleClick}
            className={classes}
            href={href || ''}
            prefetch={false}
          />
        )}
        {isCompanyLink && (
          <CompanyLink
            {...props}
            exchange={exchange}
            symbol={symbol}
            ref={(link: HTMLAnchorElement | null): void => {
              itemsRef.current[refValue] = link
            }}
            onClick={handleClick}
            className={classes}
            gaTrackingEventName={GA_TRACKING_EVENT_NAME}
            gaCategory={GA_TRACKING_CATEGORY}
            gaLabel={searchTerm}
            gaAdditionalProperties={{
              page_type: 'premium',
              link_type: linkType,
              exchange,
              symbol,
            }}
          />
        )}
      </>
    )
  }

  const RecentItem = ({
    searchTerm,
    active,
    refValue,
  }: {
    searchTerm: string
    active: boolean
    refValue: string
  }) => (
    <SearchLink
      active={active}
      refValue={refValue}
      searchTerm={searchTerm}
      href={`/search/?q=${searchTerm}`} // what's the search url params going to be?
      linkType="recent"
    >
      <SearchIcon />
      <span className="text-button font-bold">{searchTerm}</span>
    </SearchLink>
  )

  const TickerItem = ({
    symbol,
    exchange,
    name,
    quote,
    active,
    refValue,
  }: {
    active: boolean
    refValue: string
  } & GetSearchResultsQuery['tickers'][number]) => (
    <SearchLink
      active={active}
      refValue={refValue}
      searchTerm={query}
      exchange={exchange}
      symbol={symbol}
      linkType="company"
    >
      <CompanyImage
        exchange={exchange}
        symbol={symbol}
        alt={name}
        className="rounded-full bg-primary-0"
      />
      <Heading className="font-bold text-content-100" as="h5" looksLike="h5">
        {symbol}
      </Heading>
      <span className="text-body-regular text-content-70">{name}</span>
      {quote?.percentChange ? (
        <span className="ml-auto">
          <PerformanceChip value={quote.percentChange * 100} />
        </span>
      ) : null}
    </SearchLink>
  )

  const NewsItem = ({
    headline,
    path,
    active,
    refValue,
  }: {
    active: boolean
    refValue: string
  } & GetSearchResultsQuery['articles'][number]) => (
    <SearchLink
      active={active}
      refValue={refValue}
      searchTerm={query}
      href={`${path}`}
      linkType="news"
    >
      <NewsIcon />
      <Heading className="font-bold text-content-100" as="h5" looksLike="h5">
        {headline}
      </Heading>
    </SearchLink>
  )

  const RecItem = ({
    headline,
    path,
    publishAt,
    active,
    refValue,
  }: {
    active: boolean
    refValue: string
  } & GetSearchResultsQuery['recommendations'][number]) => (
    <SearchLink
      active={active}
      refValue={refValue}
      searchTerm={query}
      href={`${path}`}
      linkType="recommendation"
    >
      <RecsIcon />
      <Heading className="font-bold text-content-100" as="h5" looksLike="h5">
        {headline}
      </Heading>
      <span className="ml-auto text-content-70">
        {new Date(publishAt).toLocaleDateString()}
      </span>
    </SearchLink>
  )

  const VideoItem = ({
    headline,
    path,
    active,
    refValue,
  }: {
    active: boolean
    refValue: string
  } & GetSearchResultsQuery['videos'][number]) => (
    <SearchLink
      active={active}
      refValue={refValue}
      searchTerm={query}
      href={`${path}`}
      linkType="video"
    >
      <PlayIcon />
      <Heading className="font-bold text-content-100" as="h5" looksLike="h5">
        {headline}
      </Heading>
    </SearchLink>
  )

  const categoryMap = {
    tickers: 'Stocks',
    articles: 'Coverage',
    recommendations: 'Recommendations',
    videos: 'Videos',
    recent: 'Recent Searches',
  }

  const getRecentSearches = () => {
    try {
      if (typeof window !== 'undefined') {
        const storage = localStorage.getItem(LOCAL_STORAGE_KEY)
        return storage ? storage.split('|') : []
      }
    } catch (error) {
      console.error('Failed to get recent searches:', error)
    }
    return []
  }

  const updateRecentSearchesArray = (newSearch: string) => {
    const recent = recentSearches.filter((s) => s !== newSearch)
    setRecentSearches([newSearch, ...recent].slice(0, 5))
  }

  const [dropdownActive, setDropdownActive] = useState<boolean>(false)
  const [recentSearches, setRecentSearches] =
    useState<string[]>(getRecentSearches())
  const [displayedResults, setDisplayedResults] = useState<
    | GetSearchResultsQuery
    | {
        recent: string[]
      }
    | null
  >(null)
  const [isPending, setIsPending] = useState(false)
  const comboboxRef = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<{
    [key: string]: HTMLAnchorElement | null
  }>({})
  const comboboxInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, recentSearches.join('|'))
  }, [recentSearches])

  useEffect(() => {
    setDropdownActive(!!query)
    if (!query) setQueryResults(undefined)
  }, [query])

  const hardReset = (clearQuery = false) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }
    debouncedSearch.cancel()
    if (clearQuery) setQuery('')
    setDropdownActive(false)
  }

  const handleSessionRefresh = useCallback(async () => {
    if (sessionStatus !== 'authenticated') {
      try {
        // next-auth's session.update() is documented to trigger both
        // client-side session refresh and server-side callback execution.
        // However, in practice:
        // 1. It makes the /api/auth/session HTTP call successfully (200 response)
        // 2. But does not trigger the jwt/session callbacks on the server
        // 3. Does not generate a new JWT or session cookie
        // 4. Does not properly restore the authenticated state
        // Source: https://next-auth.js.org/getting-started/client#updating-the-session
        //
        // Due to these limitations, we fall back to silent auth redirect
        // temporarily.
        //
        // await updateSession()
        // console.debug('Session refreshed')

        const currentPath = window.location.pathname
        const queryParams = window.location.search
        const returnPath = encodeURIComponent(currentPath + queryParams)

        router.push(
          `${getAuthPath()}/signin?returnPath=${returnPath}&prompt=${SILENT_AUTH_PROMPT}`,
        )
      } catch (error) {
        console.error('Failed to refresh session:', error)
        // TODO: Perform silent auth redirect here, instead, once we get true
        // silent auth working with next-auth.
        // router.push(`${getAuthPath()}/signin?prompt=none&returnPath=${returnPath}`)
      }
    }
  }, [sessionStatus])

  const handleFocus = useCallback(async () => {
    handleSessionRefresh()
  }, [handleSessionRefresh])

  useEffect(() => {
    // Change the displayed results after the dropdown transition is complete
    let results:
      | GetSearchResultsQuery
      | {
          recent: string[]
        }
      | null = null
    if (queryResults && Object.values(queryResults).flat().length) {
      results = queryResults
    } else if (recentSearches && recentSearches.length && !query) {
      results = {recent: recentSearches}
    }
    setTimeout(() => {
      setDisplayedResults(results)
    }, 150)
  }, [recentSearches, queryResults, query])

  return (
    <FocusTrap
      active={dropdownActive}
      focusTrapOptions={{
        initialFocus: false,
        allowOutsideClick: true,
        clickOutsideDeactivates: true,
        escapeDeactivates: true,
        onDeactivate: () => {
          if (!query) hardReset()
        },
      }}
    >
      <div className={`md:relative ${customClasses}`} ref={comboboxRef}>
        <Combobox
          onChange={(value: string) => {
            if (query) {
              updateRecentSearchesArray(query)
            }
            // Combobox swallows the keyboard events, so we manually navigate on select
            const selectedElement = itemsRef.current[value]
            if (selectedElement && selectedElement.href) {
              const searchTerm = selectedElement.href.split('?q=')[1]
              if (searchTerm) {
                updateRecentSearchesArray(searchTerm)
              }
              router.push(selectedElement.href)
              comboboxInput.current && comboboxInput.current.blur()
            }
          }}
        >
          <Combobox.Input
            ref={comboboxInput}
            as={SearchInput}
            className={classNames(
              'w-full',
              'bg-content-4',
              'hover:bg-content-4',
              'visited:bg-content-4',
              'border-content-70',
              'font-bold',
              {
                'opacity-70': isPending,
              },
            )}
            inputClasses={classNames(
              'w-full',
              'rounded-[4px]',
              'bg-primary-4',
              'border-content-70',
              'text-content-70',
              'focus:border-2',
              'focus:border-primary-100',
              'focus:text-content-100',
              'focus:bg-primary-4',
              'hover:bg-primary-8 focus:hover:bg-transparent',
              'hover:border-content-80 focus:hover:border-transparent',
              'hover:text-content-70',
              'placeholder:text-content-70',
              'placeholder-shown:text-content-70 focus:text-content-100',
            )}
            placeholder="Search for stocks or articles"
            iconRight={<SearchIcon />}
            onFocus={handleFocus}
            onClick={() => setDropdownActive(true)}
            onChange={(event) => {
              setQuery(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Escape') hardReset(true)
              if (event.key === 'Enter') {
                hardReset()
                router.push(`/search/?q=${query}`)
                document.querySelector('body')?.classList.add('cursor-wait')
              }
              if (event.key === 'Backspace' && !query) {
                setDropdownActive(false)
              }
            }}
            autoComplete="off"
            value={query}
            displayValue={() => query}
          />
          <Transition
            show={dropdownActive && !loading}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`md:shadow md:rounded-[8px] z-20 md:min-w-[400px] md:absolute md:left-auto md:border-none
                  bg-primary-0 absolute mt-8px w-full p-16px left-0px border-t-1px border-b-1px border-primary-8
                  max-h-[calc(99vh-80px)] overflow-y-auto`}
            >
              <Combobox.Options>
                {displayedResults &&
                  Object.entries(displayedResults).map(
                    ([category, results], index) =>
                      !!Array.isArray(results) &&
                      !!results.length &&
                      category in categoryMap && (
                        <div key={index}>
                          {index ? <Divider className="m-8px" /> : null}
                          <span
                            key={category}
                            className="mx-8px my-4px text-caption-bold text-content-70"
                          >
                            {categoryMap[category as keyof typeof categoryMap]}
                          </span>
                          {results.map((result, index) => (
                            <Combobox.Option
                              key={category + index}
                              value={category + index}
                            >
                              {({active}) => {
                                switch (category) {
                                  case 'tickers':
                                    return (
                                      <TickerItem
                                        refValue={category + index}
                                        active={active}
                                        {...(result as GetSearchResultsQuery['tickers'][number])}
                                      />
                                    )
                                  case 'articles':
                                    return (
                                      <NewsItem
                                        refValue={category + index}
                                        active={active}
                                        {...(result as GetSearchResultsQuery['articles'][number])}
                                      />
                                    )
                                  case 'recommendations':
                                    return (
                                      <RecItem
                                        refValue={category + index}
                                        active={active}
                                        {...(result as GetSearchResultsQuery['recommendations'][number])}
                                      />
                                    )
                                  case 'videos':
                                    return (
                                      <VideoItem
                                        refValue={category + index}
                                        active={active}
                                        {...(result as GetSearchResultsQuery['videos'][number])}
                                      />
                                    )
                                  case 'recent':
                                    return (
                                      <RecentItem
                                        refValue={category + index}
                                        active={active}
                                        searchTerm={result as string}
                                      />
                                    )
                                  default:
                                    return <></>
                                }
                              }}
                            </Combobox.Option>
                          ))}
                        </div>
                      ),
                  )}
                {queryResults &&
                  !!Object.values(queryResults).flat().length && (
                    <>
                      <Divider className="m-8px" />
                      <Combobox.Option value="viewall">
                        {({active}) => (
                          <SearchLink
                            active={active}
                            refValue="viewall"
                            searchTerm={query}
                            href={`/search/?q=${query}`}
                            setIsPending={setIsPending}
                            linkType="viewall"
                          >
                            <Button
                              variant="link"
                              className={classNames(
                                'w-full self-stretch text-center',
                                isPending && 'opacity-70 cursor-wait',
                              )}
                            >
                              <span className="ml-24px text-center w-full">
                                Show all results
                              </span>
                              <ChevronRightIcon />
                            </Button>
                          </SearchLink>
                        )}
                      </Combobox.Option>
                    </>
                  )}
              </Combobox.Options>
            </div>
          </Transition>
          <Transition
            show={loading}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ul
              className={`md:shadow md:rounded-[8px] z-20 md:min-w-[400px] md:absolute md:left-auto md:border-none
                  bg-primary-0 absolute mt-8px w-full p-16px left-0px border-t-1px border-b-1px border-primary-8
                  max-h-[calc(99vh-80px)] overflow-y-auto`}
            >
              <LoadingSkeleton />
            </ul>
          </Transition>
        </Combobox>
      </div>
    </FocusTrap>
  )
}
