'use client'

import type {Session} from 'next-auth'
import {SessionProvider} from 'next-auth/react'

export const NextAuthProvider = ({
  children,
  session,
}: React.PropsWithChildren<{
  children?: React.ReactNode
  session?: Session | null
}>) => {
  return (
    <SessionProvider
      basePath={`/premium/api/auth`}
      refetchInterval={1 * 60 * 20} // 20 minutes
      // Re-fetches session when window is focused
      refetchOnWindowFocus={true}
      session={session}
    >
      {children}
    </SessionProvider>
  )
}
