'use client'

import Image, {ImageProps, ImageLoaderProps} from 'next/image'
import {useState, useEffect, useMemo, useCallback, memo} from 'react'
import {HomeIcon} from '@fool/jester-ui/Icon'

export type CompanyImageProps = {
  symbol: string
  exchange?: string
  variant?: 'mark' | 'full'
  fallbackIconSize?: 'xsm' | 'sm' | 'lg'
  fallbackClassName?: string
} & Omit<ImageProps, 'src' | 'onError' | 'loader'>

type ImageFormat = {
  id:
    | 'exchange_colon'
    | 'exchange_colon_with_whitespace' // Lord help us.
    | 'exchange_underscore'
    | 'symbol_only'
  getPath: (variant: string, symbol: string, exchange?: string) => string
}

const formats: ImageFormat[] = [
  {
    id: 'exchange_colon',
    getPath: (variant, symbol, exchange) => `${variant}/${exchange}:${symbol}`,
  },
  {
    id: 'exchange_colon_with_whitespace',
    getPath: (variant, symbol, exchange) =>
      `${variant}/${exchange}:%20${symbol}`,
  },
  {
    id: 'exchange_underscore',
    getPath: (variant, symbol, exchange) => `${variant}/${exchange}_${symbol}`,
  },
  {
    id: 'symbol_only',
    getPath: (variant, symbol) => `${variant}/${symbol}`,
  },
]

const getAvailableFormats = (hasExchange: boolean) => {
  if (!hasExchange) {
    return formats.filter((f) => f.id === 'symbol_only')
  }
  return formats
}

const imageLoader = ({src, width}: ImageLoaderProps) => {
  return `https://g.foolcdn.com/art/companylogos/${src}.png?width=${width}`
}

const CompanyImageFallback = memo(function CompanyImageFallback({
  width,
  height,
  fallbackClassName = '',
  fallbackIconSize,
}: {
  width: number
  height: number
  fallbackClassName?: string
  fallbackIconSize?: 'xsm' | 'sm' | 'lg'
}) {
  return (
    <span
      className={`${fallbackClassName} rounded-full border-1px border-primary-8 bg-primary-4 flex items-center justify-center`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <HomeIcon size={fallbackIconSize} />
    </span>
  )
})

function CompanyImage({
  symbol,
  exchange,
  variant = 'mark',
  width = 32,
  height = 32,
  fallbackIconSize,
  className = '',
  fallbackClassName = '',
  ...props
}: CompanyImageProps) {
  const [formatIndex, setFormatIndex] = useState(0)
  const [showFallback, setShowFallback] = useState(false)
  const availableFormats = useMemo(
    () => getAvailableFormats(Boolean(exchange)),
    [exchange],
  )

  const currentFormat = useMemo(
    () => availableFormats[formatIndex],
    [availableFormats, formatIndex],
  )

  useEffect(() => {
    setFormatIndex(0)
    setShowFallback(false)
  }, [symbol, exchange])

  const handleImageError = useCallback(() => {
    const nextIndex = formatIndex + 1
    if (nextIndex < availableFormats.length) {
      setFormatIndex(nextIndex)
    } else {
      setShowFallback(true)
    }
  }, [formatIndex, availableFormats])

  if (showFallback) {
    return (
      <CompanyImageFallback
        width={width as number}
        height={height as number}
        fallbackClassName={fallbackClassName}
        fallbackIconSize={fallbackIconSize}
      />
    )
  }

  return (
    <Image
      className={className}
      src={currentFormat.getPath(variant, symbol, exchange)}
      loader={imageLoader}
      onError={handleImageError}
      width={width}
      height={height}
      {...props}
    />
  )
}

export default memo(CompanyImage)
