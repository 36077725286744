'use client'

import {useEffect, useCallback} from 'react'
import {usePathname, useSearchParams} from 'next/navigation'
import {useOffsetAnchorLinks} from '../hooks/use-offset-anchor-links'

interface OffsetAnchorHandlerProps {
  offset?: number
}

export function OffsetAnchorHandler({
  offset = 80,
}: OffsetAnchorHandlerProps): null {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  useOffsetAnchorLinks(offset)

  const handleHashChange = useCallback(() => {
    const hash = window.location.hash.slice(1)
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash)
        if (element) {
          const elementPosition = element.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - offset
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          })
        }
      }, 0)
    }
  }, [offset])

  useEffect(() => {
    handleHashChange()
  }, [pathname, searchParams, handleHashChange])

  return null
}
