import {gql} from '@apollo/client/core'

export const SEARCH_QUERY = gql(`
  query GetSearchResults($searchTerm: String!, $limit: Int, $daysAgo: Int) {
    tickers: tickerSearch(searchTerm: $searchTerm, limit: $limit) {
      instrumentId
      exchange
      symbol
      name
      quote {
        percentChange
      }
    }
    recommendations: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: RECOMMENDATIONS
    ) {
      headline
      uuid
      path
      publishAt
    }
    articles: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: ARTICLES
    ) {
      headline
      uuid
      path
    }
    videos: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: VIDEOS
    ) {
      headline
      uuid
      path
    }
  }
`)

export const FULL_SEARCH_QUERY = gql(`
  query GetFullSearchResults($searchTerm: String!, $limit: Int, $daysAgo: Int) {
    tickers: tickerSearch(searchTerm: $searchTerm, limit: $limit) {
      instrumentId
      exchange
      symbol
      name
      quote {
        percentChange
        currentPrice {
            amount
            currencyCode
        }
      }
    }
    recommendations: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: RECOMMENDATIONS
    ) {
      headline
      uuid
      publishAt
      path
    }
    articles: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: ARTICLES
    ) {
      headline
      uuid
      path
    }
    videos: contentSearch(
      searchTerm: $searchTerm
      limit: $limit
      daysAgo: $daysAgo
      searchContentType: VIDEOS
    ) {
      headline
      uuid
      path
    }
  }
`)

export const SEARCH_CONTENT_ARTICLE_QUERY = gql(`
  query SearchContentArticleQuery($uuid: ID!) {
    contentDetail(uuid: $uuid, parseBody: true, rootPath: "/premium/") {
      uuid
      headline
      path
      publishAt
      byline
      promo
      averageRating
      commentCount
    }
  }
`)

export const SEARCH_CONTENT_RECOMMENDATION_QUERY = gql(`
  query SearchContentRecQuery($uuid: ID!) {
    contentDetail(uuid: $uuid, parseBody: true, rootPath: "/premium/") {
      authors {
        author {
            byline
            smallAvatarUrl
        }
      }
      aiSummary
      uuid
      headline
      path
      publishAt
      byline
      promo
      product {
        shortName
        name
      }
      recommendations {
        action
        actionDate
        instrument {
            name
            symbol
        }
      }
    }
  }
`)

export const SEARCH_CONTENT_VIDEO_QUERY = gql(`
  query SearchContentVideoQuery($uuid: ID!) {
    contentDetail(uuid: $uuid, parseBody: true, rootPath: "/premium/") {
      uuid
      headline
      path
      publishAt
      byline
      promo
      averageRating
      commentCount
      product {
        shortName
      }
      rating {
        calculatedScore
      }
      images {
        featured
        name
        url
      }
      video {
        duration
      }
      authors {
        author {
          authorId
          byline
        }
      }
    }
  }
`)
