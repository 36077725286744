import(/* webpackMode: "eager", webpackExports: ["UserWayLoader"] */ "/app/apps/epic/src/app/(main)/a11y/userway.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/epic/src/app/(main)/layout.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/app/(main)/tracking/BrazeLogger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Infotrack"] */ "/app/apps/epic/src/app/(main)/tracking/infotrack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/app/monitoring/datadog-rum.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/apps/epic/src/components/common/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/common/site-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/header-left.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/header-ticker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/header-title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/main-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/main-nav-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/navigation/fool24-button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/navigation/mobile-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/navigation/new-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteChangeListener"] */ "/app/apps/epic/src/components/layout/navigation/route-change-listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/profile-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/right-bar-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/layout/vault-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/notification/toast-notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OffsetAnchorHandler"] */ "/app/apps/epic/src/components/offset-anchor-link-handler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/tooltip/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/app/apps/epic/src/components/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/epic/src/components/usersnap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/apps/epic/src/lib/apollo-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/app/apps/epic/src/lib/next-auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/app/apps/epic/src/lib/redux-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/epic/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/font.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-satoshi\",\"src\":\"../public/fonts/Satoshi-Variable.ttf\",\"weight\":\"100 1000\"}],\"variableName\":\"satoshi\"}");
